import { Injectable } from '@angular/core';
import { IReason } from '@shared/models/reason.model';

@Injectable({
  providedIn: 'root',
})
export class ReasonService {
  static readonly NOT_AVAILABLE_REASON_CODE = 'N/A';

  constructor() {}

  getReasonList(): IReason[] {
    const list: IReason[] = [];
    list.push(new IReason(39, ReasonService.NOT_AVAILABLE_REASON_CODE));
    list.push(new IReason(1, '1', true));
    list.push(new IReason(2, '10', true));
    list.push(new IReason(3, '11', true));
    list.push(new IReason(4, '13', true));
    list.push(new IReason(5, '19', true));
    list.push(new IReason(6, '35', true));
    list.push(new IReason(7, '3W'));
    list.push(new IReason(8, '3X'));
    list.push(new IReason(9, '3Y'));
    list.push(new IReason(10, '4', true));
    list.push(new IReason(11, '40', true));
    list.push(new IReason(12, '5', true));
    list.push(new IReason(13, '50', true));
    list.push(new IReason(14, '54', true));
    list.push(new IReason(15, '569', true));
    list.push(new IReason(16, '6', true));
    list.push(new IReason(17, '60'));
    list.push(new IReason(18, '608'));
    list.push(new IReason(19, '61'));
    list.push(new IReason(20, '65', true));
    list.push(new IReason(21, '66'));
    list.push(new IReason(22, '68', true));
    list.push(new IReason(23, '7', true));
    list.push(new IReason(24, '70'));
    list.push(new IReason(25, '73'));
    list.push(new IReason(26, '75'));
    list.push(new IReason(27, '76'));
    list.push(new IReason(28, '78'));
    list.push(new IReason(29, '8', true));
    list.push(new IReason(30, '85', true));
    list.push(new IReason(31, '88'));
    list.push(new IReason(32, '9', true));
    list.push(new IReason(33, 'AJ'));
    list.push(new IReason(34, 'E1', true));
    list.push(new IReason(35, 'E7', true));
    list.push(new IReason(36, 'G4'));
    list.push(new IReason(37, 'G5'));
    list.push(new IReason(38, 'MR'));
    list.push(new IReason(40, 'R6', true));

    return list;
  }

  isReasonCodeBlocked(code: string): boolean {
    const reasonCode = this.getReasonList().find(reason => reason.code === code);
    return !reasonCode || reasonCode.blocked;
  }

  isAReasonCodeInListBlocked(codes: string[]): boolean {
    return codes.some(code => this.isReasonCodeBlocked(code));
  }
}
