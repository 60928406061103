import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UserStoreService } from '@services/user-store.service';
import { WindowService } from '@services/window.service';
import { AgreementType } from '@shared/models/agreement-type.enum';
import { AgreementService } from '@services/agreement.service';
import { Agreement } from '@shared/models/agreement.model';
import { Router } from '@angular/router';
import { DateService } from '@services/date.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {
  @ViewChild('agreementContainer') agreementContainer: ElementRef<HTMLElement>;
  currentAgreement: AgreementType = AgreementType.USER_AGREEMENT;
  agreementDocuments: { [pharmacy: string]: Agreement[] } = {};

  isMobile = false;

  areAgreementsLoading = false;

  unsubscribe = new Subject<void>();

  constructor(
    private windowService: WindowService,
    private agreementService: AgreementService,
    private userStoreService: UserStoreService,
    private dateService: DateService,
    private router: Router,
  ) {
    this.setupSubscriptions();
  }

  ngOnInit() {
    this.loadDocuments();
  }

  onPrint(agreementId: AgreementType) {
    this.areAgreementsLoading = true;

    this.currentAgreement = agreementId;

    setTimeout(() => {
      // give the component time to be loaded
      const innerContents = this.agreementContainer.nativeElement.innerHTML;

      const styles = this.getPageStyles();

      const windowFeatures = 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no';
      const popupWindow = window.open('', '_blank', windowFeatures);
      if (!popupWindow) {
        return;
      }
      popupWindow.document.open();
      popupWindow.document.write(
        '<html><head><link rel="stylesheet" type="text/css" href="styles.css" /><style>' +
          styles +
          '</style></head><body onload="window.print()" onafterprint="self.close()">' +
          innerContents +
          '</html>',
      );
      popupWindow.document.close();

      this.areAgreementsLoading = false;
    }, 300);
  }

  formatDate(date?: string) {
    return date ? this.dateService.formatDateToAmericanStandard(new Date(Date.parse(date))) : '';
  }

  private getPageStyles(): string {
    let styles = '';

    document.querySelectorAll('style').forEach(element => {
      styles += element.innerHTML;
    });

    return styles;
  }

  private loadDocuments() {
    this.areAgreementsLoading = true;
    this.userStoreService
      .getPharmacies()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(pharmacies => {
        pharmacies.forEach(pharmacy => {
          if (pharmacy.accepted_agreements) {
            const agreements = this.agreementService.getAgreementsFromReducedAgreements(pharmacy.accepted_agreements);
            const pharmacyKey = `${pharmacy.name} (${pharmacy.ncpdp})`;
            this.agreementDocuments[pharmacyKey] = agreements;
          }
        });
        this.areAgreementsLoading = false;
      });
  }

  private setupSubscriptions(): void {
    this.windowService.width
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(width => (this.isMobile = width < this.windowService.breakpoints.md));
  }

  goHome(): void {
    // Expected to only be called by RetailPharmacy users
    this.router.navigate(['/retail']);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
