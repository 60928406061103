export class IReason {
  constructor(
    private _id: number,
    private _code: string,
    _blocked = false,
  ) {
    this.id = _id;
    this.code = _code;
    this.description = _code;
    this.blocked = _blocked;
  }

  id: number;
  code: string;
  description: string;
  verified?: boolean;
  blocked: boolean;
  hasBeenValidated?: boolean = false;
}
