<div class="loader spin-me" *ngIf="areAgreementsLoading"></div>

<div class="dashboard body_img admin-form">
  <!--  <div class="documents__back-container">-->
  <!--    <a class="documents__back-button" (click)="goHome()">-->
  <!--      <img class="documents__back-icon" src="assets/icons/arrow-back.svg" />-->
  <!--      Go back-->
  <!--    </a>-->
  <!--  </div>-->
  <div class="row pb-3 pt-3">
    <div class="col-md-2">
      <p class="main-scrn-title" data-cy="agreementsPageTitle">Agreements</p>
    </div>
    <div class="col-md-10"></div>
  </div>

  <div class="px-3 document-container">
    <div *ngIf="!isMobile" class="row font-12 boldMe user-header documents__header">
      <div class="col-md-3">NAME</div>
      <div class="col-md-3">STORE NAME (NABP)</div>
      <div class="col-md-2">AGREEMENT&nbsp;VERSION</div>
      <div class="col-md-2">DATE ACCEPTED</div>
    </div>

    <div *ngIf="!isMobile" class="row scrollbar-container">
      <perfect-scrollbar>
        <ng-container *ngTemplateOutlet="pageContent"></ng-container>
      </perfect-scrollbar>
    </div>

    <ng-container *ngIf="isMobile">
      <ng-container *ngTemplateOutlet="pageContent"></ng-container>
    </ng-container>
  </div>

  <div #agreementContainer id="agreement-container" class="agreement">
    <ng-template [appAgreement]="currentAgreement" [showHeader]="true"> </ng-template>
  </div>
  <app-footer> </app-footer>
</div>

<!-- Templates -->

<ng-template #pageContent>
  <div *ngIf="!isMobile" class="documents__content-container">
    <!-- Default & Tablet Layout -->
    <ng-container *ngIf="!isMobile">
      <ng-container *ngFor="let pharmacyAgreements of agreementDocuments | keyvalue">
        <div class="row user-rows px-3" *ngFor="let agreement of pharmacyAgreements.value">
          <div class="col-md-3">
            <ng-container *ngTemplateOutlet="showAgreement; context: { file: agreement?.file, label: agreement.name }"> </ng-container>
          </div>
          <div class="col-md-3">{{ pharmacyAgreements.key }}</div>
          <div class="col-md-2">{{ formatDate(agreement?.dateModified) }}</div>
          <div class="col-md-2">{{ formatDate(agreement?.dateAccepted) }} - {{ agreement?.username }}</div>
          <div class="col-md-2 text-right">
            <ng-container *ngTemplateOutlet="saveAgreement; context: { file: agreement.file }"></ng-container>
            <ng-container *ngTemplateOutlet="printAgreement; context: { agreementId: agreement.id }"></ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="isMobile">
    <ng-container *ngFor="let pharmacyAgreements of agreementDocuments | keyvalue">
      <div *ngFor="let agreement of pharmacyAgreements.value" class="agreement-card">
        <div class="agreement-card__content">
          <div class="agreement-card__file">
            <ng-container *ngTemplateOutlet="showAgreement; context: { file: agreement?.file, label: agreement.name }"></ng-container>
          </div>

          <div><span class="agreement-card__title">Store Name (NABP)</span></div>
          <div>{{ pharmacyAgreements.key }}</div>
          <div><span class="agreement-card__title">Agreement version</span></div>
          <div>{{ formatDate(agreement?.dateModified) }}</div>
          <div><span class="agreement-card__title">Date accepted</span></div>
          <div>{{ formatDate(agreement?.dateAccepted) }} - {{ agreement?.username }}</div>
        </div>

        <div class="agreement-card__divider"></div>
        <div class="agreement-card__sub-details">
          <ng-container *ngTemplateOutlet="saveAgreement; context: { file: agreement.file }"></ng-container>
          <ng-container *ngTemplateOutlet="printAgreement; context: { agreement: agreement.id }"></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #showAgreement let-file="file" let-label="label">
  <img class="icon saveAgreement agreementPdfIcon mr-2" src="assets/icons/pdf-icon.svg" />
  <a href="assets/pdf/{{ file }}" target="_blank" class="pointer"> {{ label }} </a>
</ng-template>

<ng-template #saveAgreement let-file="file">
  <a href="assets/pdf/{{ file }}" target="_blank" class="pointer mr-2">
    <img class="icon saveAgreement" src="assets/icons/save.svg" />
    <img class="icon saveAgreement hovered" src="assets/icons/save.svg" />
  </a>
</ng-template>

<ng-template #printAgreement let-agreementId="agreementId">
  <a (click)="onPrint(agreementId)" class="pointer">
    <img class="icon icon--print printAgreement" src="assets/icons/print.svg" />
    <img class="icon icon--print printAgreement hovered" src="assets/icons/print.svg" />
  </a>
</ng-template>
